var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-1"},[_c('b-card',{staticClass:"mt-1",attrs:{"header":"Indirizzo","header-tag":"header"}},[_c('b-card-text',[_c('b-row',[_c('div',{staticClass:"form-group col-md-3"},[_c('base-select',{attrs:{"vid":"home_country","name":_vm.beECForm.attribute_NOR_value.label,"label":_vm.coass ? 'Nazione' : _vm.beECForm.attribute_NOR_value.label,"options":_vm.inputVal.optHomeCountries,"taggable":false,"multiple":false,"closeOnSelect":true,"disabled":_vm.disabled},on:{"select":_vm.onSelectHomeCountry},model:{value:(_vm.inputVal[_vm.repository].attribute_NOR_value),callback:function ($$v) {_vm.$set(_vm.inputVal[_vm.repository], "attribute_NOR_value", $$v)},expression:"inputVal[repository].attribute_NOR_value"}})],1),_vm._v(" "),(!_vm.isForeign)?_c('div',{staticClass:"form-group col-md-3"},[_c('base-select',{attrs:{"name":"home_province","vid":"home_province","label":"Provincia","options":_vm.inputVal.optHomeProvinces,"taggable":false,"multiple":false,"closeOnSelect":true,"rules":_vm.dynamicRules ? _vm.dynRules('home_province') : _vm.rules.home_province,"disabled":_vm.disabled},on:{"select":_vm.onSelectHomeProvince,"remove":_vm.onRemoveHomeProvince},model:{value:(_vm.inputVal.home_province),callback:function ($$v) {_vm.$set(_vm.inputVal, "home_province", $$v)},expression:"inputVal.home_province"}})],1):_vm._e(),_vm._v(" "),(!_vm.isForeign)?_c('div',{staticClass:"form-group col-md-3"},[_c('base-select',{attrs:{"vid":"attribute_CITY_value","name":_vm.beECForm.attribute_CITY_value.label,"label":_vm.beECForm.attribute_CITY_value.label,"options":_vm.inputVal.optHomeCouncils,"taggable":false,"multiple":false,"closeOnSelect":true,"rules":_vm.dynamicRules
                ? _vm.dynRules('attribute_CITY_value')
                : _vm.rules.attribute_CITY_value,"disabled":_vm.disabled},model:{value:(_vm.inputVal[_vm.repository].attribute_CITY_value),callback:function ($$v) {_vm.$set(_vm.inputVal[_vm.repository], "attribute_CITY_value", $$v)},expression:"inputVal[repository].attribute_CITY_value"}})],1):_c('div',{staticClass:"form-group col-md-3"},[_c('base-input',{attrs:{"vid":"attribute_CITY2_value","name":_vm.beECForm.attribute_CITY2_value.label,"label":_vm.beECForm.attribute_CITY2_value.label,"placeholder":"Inserisci il comune estero","rules":_vm.dynamicRules
                ? _vm.dynRules('attribute_CITY2_value')
                : _vm.rules.attribute_CITY2_value,"disabled":_vm.disabled},model:{value:(_vm.inputVal[_vm.repository].attribute_CITY2_value),callback:function ($$v) {_vm.$set(_vm.inputVal[_vm.repository], "attribute_CITY2_value", $$v)},expression:"inputVal[repository].attribute_CITY2_value"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-3"},[_c('base-input',{attrs:{"vid":"attribute_ZIPC_value","name":_vm.beECForm.attribute_ZIPC_value.label,"label":_vm.beECForm.attribute_ZIPC_value.label,"placeholder":"Inserisci il CAP","maxlength":"5","rules":_vm.dynamicRules
                ? _vm.dynRules('attribute_ZIPC_value')
                : _vm.rules.attribute_ZIPC_value,"disabled":_vm.disabled},model:{value:(_vm.inputVal[_vm.repository].attribute_ZIPC_value),callback:function ($$v) {_vm.$set(_vm.inputVal[_vm.repository], "attribute_ZIPC_value", $$v)},expression:"inputVal[repository].attribute_ZIPC_value"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-3"},[_c('base-textarea',{attrs:{"vid":"attribute_ADDR_value","name":_vm.beECForm.attribute_ADDR_value.label,"label":_vm.beECForm.attribute_ADDR_value.label,"placeholder":"Inserisci un indirizzo","rules":_vm.dynamicRules
                ? _vm.dynRules('attribute_ADDR_value')
                : _vm.rules.attribute_ADDR_value,"disabled":_vm.disabled},model:{value:(_vm.inputVal[_vm.repository].attribute_ADDR_value),callback:function ($$v) {_vm.$set(_vm.inputVal[_vm.repository], "attribute_ADDR_value", $$v)},expression:"inputVal[repository].attribute_ADDR_value"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-3"},[_c('base-input',{attrs:{"vid":"attribute_NAME2_value","name":_vm.beECForm.attribute_NAME2_value.label,"label":_vm.beECForm.attribute_NAME2_value.label,"placeholder":"Inserisci il nominativo","rules":_vm.dynamicRules
                ? _vm.dynRules('attribute_NAME2_value')
                : _vm.rules.attribute_NAME2_value,"disabled":_vm.disabled},model:{value:(_vm.inputVal[_vm.repository].attribute_NAME2_value),callback:function ($$v) {_vm.$set(_vm.inputVal[_vm.repository], "attribute_NAME2_value", $$v)},expression:"inputVal[repository].attribute_NAME2_value"}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }