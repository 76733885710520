<script>
// import { RepositoryFactory } from "@/repositories/RepositoryFactory";
// import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
// import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import CopyCommissionsModal from "@/components/modals/copyCommissionsModal";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";

export default {
  mixins: [StorageGetterMixin],
  // mixins: [CustomFiltersMixin, ConfirmModalMixin],
  components: { CopyCommissionsModal },
  methods: {
    loadData(level, risk_branch_id = null, insurance_risk_id = null) {
      switch (level) {
        case 1:
          this.filter.byRiskBranch.id = null;
          this.filter.byInsuranceRisk.id = null;
          this.fetch(level).then(() => {
            this.showForm = true;
            this.expand(1);
          });
          break;
        case 2:
          this.filter.byInsuranceRisk.id = null;
          this.filter.byRiskBranch.id = risk_branch_id;
          this.fetch(level).then(() => {
            this.collapse(1);
            this.expand(2);
          });
          break;
        case 3:
          this.filter.byInsuranceRisk.id = insurance_risk_id;
          this.fetch(level).then(() => {
            this.collapse(2);
            this.expand(3);
          });
          break;
        default:
          this.showForm = false;
          break;
      }
    },
    deleteData(level, index) {
      let type = "pdata";
      let commission_id = this.form[`level${level}`][type][index].commission_id;
      // Message
      this.showConfirm({
        yesCallback: () => {
          this.deleteCommission(commission_id, level, this.repository, type);
        },
        noCallback: null,
        title: "Conferma Eliminazione",
        message: "Vuoi davvero eliminare?",
        yesLabel: "ELIMINA",
        noLabel: "ANNULLA",
      });
    },
    /**
     * @param {Object} options - { skipThen: false, filter: null }
     * skipThen: do not showSnaknar and reload table
     * filter: apply the given filter value depending on level, which replaces the one set by "save" button click
     * level 1: fixed value, from global insurer id
     * level 2: row data value, for insurance_risk
     * level 3: row data value, for breakdown
     *
     * NOTE: options is required by "saveAll" only; do not pass otherwise.
     */
    storeData(level, index, options = { skipThen: false, filter: null }) {
      if (!options || !options.skipThen) {
        this.isLoading = true;
      }
      let type = "npdata";
      let payload = {
        purchase_calculation_type:
          this.form[`level${level}`][type][index].purchase_calculation_type,
        purchase_commission:
          this.form[`level${level}`][type][index].purchase_commission,
        take_calculation_type:
          this.form[`level${level}`][type][index].take_calculation_type,
        take_commission:
          this.form[`level${level}`][type][index].take_commission,
      };
      payload.broker_id = this.salesman_id;

      switch (level) {
        case 1:
          payload.insurer = {};
          payload.insurer[this.filter.byInsurer.id] = {};
          payload.risk_branch = {};
          payload.risk_branch[this.form[`level${level}`][type][index].id] = {};
          break;
        case 2:
          payload.insurance_risk = {};
          payload.insurance_risk[
            options.filter ? options.filter : this.filter.byInsuranceRisk.id
          ] = {};
          break;
        case 3:
          payload.breakdown = {};
          payload.breakdown[
            options.filter ? options.filter : this.filter.byBreakdown.id
          ] = {};
          break;
        default:
          break;
      }
      this.$set(this.buttons[`level${level}`], type, {});
      return this.storeCommission(payload)
        .then(() => {
          if (!options || !options.skipThen) {
            this.$showSnackbar({
              preset: "success",
              text: `Azione Completata: Capitolato salvato`,
            });
            // reload
            this.loadData(
              level,
              this.filter.byRiskBranch.id,
              this.filter.byInsuranceRisk.id
            );
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateData(level, index, options = { skipThen: false, filter: null }) {
      if (!options || !options.skipThen) {
        this.isLoading = true;
      }
      let type = "pdata";
      let commission_id = this.form[`level${level}`][type][index].commission_id;
      let payload = {
        purchase_calculation_type:
          this.form[`level${level}`][type][index].purchase_calculation_type,
        purchase_commission:
          this.form[`level${level}`][type][index].purchase_commission,
        take_calculation_type:
          this.form[`level${level}`][type][index].take_calculation_type,
        take_commission:
          this.form[`level${level}`][type][index].take_commission,
      };
      payload.broker_id = this.salesman_id;

      switch (level) {
        case 1:
          payload.insurer = {};
          payload.insurer[this.filter.byInsurer.id] = {};
          payload.risk_branch = {};
          payload.risk_branch[this.form[`level${level}`][type][index].id] = {};
          break;
        case 2:
          payload.insurance_risk = {};
          payload.insurance_risk[
            options.filter ? options.filter : this.filter.byInsuranceRisk.id
          ] = {};
          break;
        case 3:
          payload.breakdown = {};
          payload.breakdown[
            options.filter ? options.filter : this.filter.byBreakdown.id
          ] = {};
          break;
        default:
          break;
      }
      this.$set(this.buttons[`level${level}`], type, {});
      return this.updateCommission(commission_id, payload)
        .then(() => {
          if (!options || !options.skipThen) {
            this.$showSnackbar({
              preset: "success",
              text: `Azione Completata: Capitolato aggiornato`,
            });
            // reload
            this.loadData(
              level,
              this.filter.byRiskBranch.id,
              this.filter.byInsuranceRisk.id
            );
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cloneData(brokerId, insurerId, payload) {
      this.isLoading = true;
      this.cloneCommission(brokerId, insurerId, payload)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Capitolato copiato`,
          });
          // // reload
          // this.loadData(
          //   level,
          //   this.filter.byRiskBranch.id,
          //   this.filter.byInsuranceRisk.id
          // );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    saveAll(level) {
      this.isLoading = true;
      // store
      let promises = [];
      for (const [key, value] of Object.entries(
        this.buttons[`level${level}`].npdata
      )) {
        if (value.enabled) {
          let id = this.form[`level${level}`].npdata[key].id;
          promises.push(
            this.storeData(level, key, { skipThen: true, filter: id })
          );
        }
      }
      // update
      for (const [key, value] of Object.entries(
        this.buttons[`level${level}`].pdata
      )) {
        if (value.enabled) {
          let id = this.form[`level${level}`].pdata[key].id;
          promises.push(
            this.updateData(level, key, { skipThen: true, filter: id })
          );
        }
      }
      Promise.all(promises)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Tutti i dati salvati`,
          });
          // reload
          this.loadData(
            level,
            this.filter.byRiskBranch.id,
            this.filter.byInsuranceRisk.id
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    enableButton(level, type, index) {
      if (!this.buttons[`level${level}`][type][index]) {
        this.buttons[`level${level}`][type][index] = {};
      }
      this.buttons[`level${level}`][type][index].enabled = true;
    },
    isDisabled(level, type, index) {
      return (
        !Object.hasOwnProperty.call(
          this.buttons[`level${level}`][type],
          index
        ) ||
        (Object.hasOwnProperty.call(
          this.buttons[`level${level}`][type][index],
          "enabled"
        ) &&
          !this.buttons[`level${level}`][type][index].enabled)
      );
    },
    areAllDisabled(level) {
      let disabled = true;
      for (const type of ["pdata", "npdata"]) {
        for (const index in this.buttons[`level${level}`][type]) {
          disabled &&= this.isDisabled(level, type, index);
          if (!disabled) break;
        }
        if (!disabled) break;
      }
      return disabled;
    },
    getStringValue(resource, id, field) {
      let item;
      let text = "";
      switch (resource) {
        case "salesmen":
        case "agency_brokers":
        case "cooperators":
          item = this.$store.state.auth[resource].find((e) => e.id == id);
          // text = `${item.attributables.SURN} ${item.attributables.NAME}`;
          text = item.title;
          break;

        default:
          item = this.$store.state.auth[resource].find((item) => item.id == id);
          text = item ? item[field] : "N/A";
          break;
      }

      return text;
    },
  },
  created() {
    /* */
    console.log("created common");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("agency_brokers"))
      resources.push(
        this.$store
          .dispatch(`${path}agency_brokers`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("cooperators"))
      resources.push(
        this.$store
          .dispatch(`${path}cooperators`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
  },
  computed: {
    brokersOptions() {
      // return [
      //   { value: 1, text: "UNO" },
      //   { value: 2, text: "DUE" },
      // ];
      let ret = this.getSalesmen().filter((e) => e.value !== this.salesman_id);
      // .map((e) => {
      //   return { value: e.id, text: e.title };
      // });
      console.log(ret);
      return ret;
    },
  },
};
</script>
